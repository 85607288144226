import React from 'react';
import {Platform, StyleSheet, TouchableOpacity, ViewStyle} from 'react-native';
import {Icon} from 'react-native-elements';
const hex2rgb = require('hex2rgb');

const getRGB = (color: string) => {
  const bg = hex2rgb(color, {rgbStringDefault: 'transparent'}).rgb;
  return `rgba(${bg[0]}, ${bg[1]}, ${bg[2]}, 0.4)`;
};

interface BackButtonVProps {
  iconName: string;
  iconType: string;
  iconSize?: number;
  iconColor?: string;
  iconContainerStyle?: ViewStyle;
  onIconPress?: () => void;
  iconBgColor?: string;
  iconStyle?: ViewStyle;
}

const ICON_SIZE = 22;
const hitSlop = {top: 20, right: 20, left: 20, bottom: 20};

export const BackButtonV = ({
  iconName,
  iconType,
  iconSize,
  iconColor,
  iconContainerStyle,
  onIconPress,
  iconBgColor,
  iconStyle,
}: BackButtonVProps): JSX.Element => {
  return (
    <Icon
      name={iconName}
      type={iconType}
      size={iconSize ?? ICON_SIZE}
      color={iconColor}
      //@ts-ignore
      containerStyle={StyleSheet.flatten([
        styles.iconContainerStyle,
        iconContainerStyle,
        // iconBgColor && {backgroundColor: getRGB(iconBgColor)},
        iconBgColor && {backgroundColor: iconBgColor},
      ])}
      onPress={onIconPress}
      underlayColor={'transparent'}
      iconStyle={StyleSheet.flatten([styles.iconStyle, iconStyle])}
      Component={TouchableOpacity}
      hitSlop={hitSlop}
    />
  );
};

const styles = StyleSheet.create({
  iconContainerStyle: {
    position: 'absolute',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    top: 10,
    left: 10,
    height: ICON_SIZE + 5,
    width: ICON_SIZE + 5,
    borderRadius: (ICON_SIZE + 5) / 2,
    zIndex: 9999,
    elevation: 4,
    ...Platform.select({
      android: {
        elevation: 0,
      },
    }),
  },
  iconStyle: {
    alignSelf: 'center',
    // top: 1,
  },
});

BackButtonV.defaultProps = {
  iconName: 'chevron-circle-left',
  iconType: 'font-awesome',
  iconColor: '#000',
  iconSize: ICON_SIZE,
  iconBgColor: '#d3d3d3',
} as Partial<BackButtonVProps>;
