import React from "react";
import { ViewportProvider } from "@vlinder-web/viewport-provider-react";
import { AppNavigator } from "../AppNavigator";

import { ThemeProvider } from "../providers/theme";
import { Accessories } from "./Accessories";

import { DEFAULT_BLUEPRINT_CONFIG } from "../config/default.blueprint";

export const BootstrapApp = () => {
  return (
    <>
      <ThemeProvider>
        <ViewportProvider>
          <AppNavigator />
        </ViewportProvider>
      </ThemeProvider>
      <Accessories />
    </>
  );
};
