import React, { Component } from "react";
import { Icon } from "react-native-elements";
import shouldPureComponentUpdate from "react-pure-render/function";

export default class Pointer extends Component {
  

    static defaultProps = {};

    shouldComponentUpdate = shouldPureComponentUpdate;

    render() {
        const K_WIDTH = 40;
        const K_HEIGHT = 40;
        return (
            <div
                style={{
                    position: "absolute",
                    width: K_WIDTH,
                    height: K_HEIGHT,
                    left: -K_WIDTH / 2,
                    top: -K_HEIGHT / 2,

                    border: "5px solid #f44336",
                    borderRadius: K_HEIGHT,
                    backgroundColor: "white",
                    textAlign: "center",
                    color: "#3f51b5",
                    fontSize: 14,
                    fontWeight: "bold",
                    padding: 4,
                }}
            >
                <Icon name={"place"} />
                {/* {this.props.text} */}
            </div>
        );
    }
}
